import authAxios from '@/utils/authAxios';

export default {
  evaluate: ({ reservation_id, comments, good }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(
          process.env.VUE_APP_BACKEND_URL +
            `/reservation/evaluate-reservation/${reservation_id}`,
          {
            comments,
            good,
          }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  checkPendingEvaluations: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `/reservation/check-pending-evaluations`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
