<template>
  <div class="flex flex-col justify-start items-start min-height">
    <evaluation-modal
      v-if="evaluation_toggle"
      v-model="evaluation_toggle"
      :reservation="evaluation_reservation"
      @then="updateData"
    />
    <span class="text-xl text-gray-900 font-semibold"
      >Próximas Reservas <small>[{{ next.length }}]</small></span
    >
    <div class="w-full">
      <table-component
        class="w-full mt-4"
        :loading="loadingNext"
        :head="[
          { key: 'code', label: 'Código' },
          { key: 'name', label: 'Nombre Profesional' },
          { key: 'date', label: 'Fecha Reserva' },
          { key: 'status', label: 'Estado Reserva' },
          { key: 'bond', label: 'Cargar Bono' },
        ]"
        :body="bodyNext"
      />
    </div>
    <span class="text-xl text-gray-900 font-semibold mt-8"
      >Reservas pasadas <small>[{{ purchases.length }}]</small></span
    >
    <table-component
      class="w-full mt-4"
      :loading="loadingPast"
      :head="[
        { key: 'code', label: 'Código' },
        { key: 'name', label: 'Nombre Profesional' },
        { key: 'date', label: 'Fecha Reserva' },
        { key: 'status', label: 'Estado Reserva' },
        { key: 'bond', label: 'Cargar Bono' },
        { key: 'evaluation', label: 'Evaluación' },
      ]"
      :body="bodyPast"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import PurchaseService from '@/services/purchaseService';
import Table from '@/components/dashboard/user/admin/Table.vue';
import EvaluationModal from '@/views/dashboard/user/patient/evaluation/EvaluationModal.vue';
import EvaluationService from '@/services/evaluationService';

export default {
  name: 'Reservations',
  components: {
    'table-component': Table,
    'evaluation-modal': EvaluationModal,
  },
  data() {
    return {
      purchases: [],
      next: [],
      loadingNext: true,
      loadingPast: true,
      evaluation_toggle: false,
      evaluation_reservation: null,
    };
  },
  created() {
    this.checkPendingEvaluations();
    this.loadData();
  },
  methods: {
    checkPendingEvaluations() {
      EvaluationService.checkPendingEvaluations()
        .then(response => {
          if (response.data.reservation) {
            this.evaluation_reservation = response.data.reservation;
            console.log(this.evaluation_reservation.block.start_date);
            this.evaluation_toggle = true;
          }
        })
        .catch(err => {
          console.log(`failed to check pending evaluations: ${err}`);
        });
    },
    updateData(event) {
      this.evaluation_toggle = false;
      this.evaluation_reservation = null;
      if (event.update) {
        this.loadData();
      }
    },
    loadData() {
      this.purchases = [];
      this.next = [];
      this.loadingNext = true;
      this.loadingPast = true;
      this.evaluation_toggle = false;
      this.evaluation_reservation = null;
      PurchaseService.getNext()
        .then(response => {
          this.next = response.data;
          this.loadingNext = false;
        })
        .catch(err => {
          console.log(err);
        });
      PurchaseService.getAll()
        .then(response => {
          this.purchases = response.data;
          this.loadingPast = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    formatDateNative(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('L');
    },
    formatDateNativeHour(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('HH:mm A');
    },
    dateToMoment(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp;
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
    bodyNext() {
      let temp = this.next;
      temp = temp.sort(function(a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      return temp.map(e => {
        return {
          code: [{ label: e.id, class: 'uppercase' }],
          name: [
            e.block && e.block.user
              ? {
                  label: `${e.block.user.names} ${e.block.user.lastnames}`,
                  class: 'capitalize !w-10',
                }
              : {},
            {
              label:
                e.block && e.block.user && e.block.user.doctor
                  ? e.block.user.doctor.specialty
                  : e.block && e.block.user && e.block.user.superdoctor
                  ? e.block.user.superdoctor.specialty
                  : e.block && e.block.user && e.block.user.psychologist
                  ? e.block.user.psychologist.specialty
                  : '',
              class:
                'w-auto text-green-900 bg-green-100 rounded-md px-2 mt-1 lowercase',
            },
          ],
          date: [
            {
              label: e.block ? this.formatDateNative(e.block.start_date) : '',
            },
            {
              label: e.block
                ? this.formatDateNativeHour(e.block.start_date)
                : '',
            },
          ],
          status: [
            {
              label:
                e.bond && e.bond.accepted
                  ? 'Confirmada'
                  : e.bond && e.bond.rejected
                  ? 'Rechazado'
                  : e.bond
                  ? 'Pendiente aprobación'
                  : 'Pendiente pago',
              class:
                e.bond && e.bond.accepted
                  ? 'text-green-900'
                  : e.bond && e.bond.rejected
                  ? 'text-red-900'
                  : e.bond
                  ? 'text-yellow-900'
                  : 'text-blue-900',
            },
          ],

          bond: [
            {
              label:
                e.bond && e.bond.accepted
                  ? 'Modificar Bono'
                  : e.bond && e.bond.rejected
                  ? 'Modificar Bono'
                  : e.bond
                  ? 'Modificar Bono'
                  : 'Adjuntar Bono',
              class: 'text-green-600 hover:text-green-700 underline text-sm',
              action: () => {
                this.$router.push({ path: `/patient/bond/${e.id}` });
              },
            },
          ],
        };
      });
    },
    bodyPast() {
      let temp = this.purchases;
      temp = temp.sort(function(a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      return temp.map(e => {
        return {
          code: [{ label: e.id, class: 'uppercase' }],
          name: [
            e.block && e.block.user
              ? {
                  label: `${e.block.user.names} ${e.block.user.lastnames}`,
                  class: 'capitalize',
                }
              : {},
            {
              label:
                e.block && e.block.user && e.block.user.doctor
                  ? e.block.user.doctor.specialty
                  : e.block && e.block.user && e.block.user.superdoctor
                  ? e.block.user.superdoctor.specialty
                  : e.block && e.block.user && e.block.user.psychologist
                  ? e.block.user.psychologist.specialty
                  : '',
              class:
                'w-auto text-green-900 bg-green-100 rounded-md px-2 mt-1 lowercase',
            },
          ],
          date: [
            {
              label: e.block ? this.formatDateNative(e.block.start_date) : '',
            },
            {
              label: e.block
                ? this.formatDateNativeHour(e.block.start_date)
                : '',
            },
          ],
          status: [
            {
              label:
                e.bond && e.bond.accepted
                  ? 'Confirmada'
                  : e.bond && e.bond.rejected
                  ? 'Rechazado'
                  : e.bond
                  ? 'Pendiente aprobación'
                  : 'Pendiente pago',
              class:
                e.bond && e.bond.accepted
                  ? 'text-green-900'
                  : e.bond && e.bond.rejected
                  ? 'text-red-900'
                  : e.bond
                  ? 'text-yellow-900'
                  : 'text-blue-900',
            },
          ],
          bond: [
            {
              label:
                e.bond && e.bond.accepted
                  ? 'Modificar Bono'
                  : e.bond && e.bond.rejected
                  ? 'Modificar Bono'
                  : e.bond
                  ? 'Modificar Bono'
                  : 'Adjuntar Bono',
              class: 'text-green-600 hover:text-green-700 underline text-sm',
              action: () => {
                this.$router.push({ path: `/patient/bond/${e.id}` });
              },
            },
          ],

          evaluation: [
            {
              label:
                e.block && e.block.session && e.block.session.is_finish
                  ? e.evaluation
                    ? 'Evaluada'
                    : 'Evaluar'
                  : 'Sin finalizar',
              class:
                !e.evaluation &&
                e.block &&
                e.block.session &&
                e.block.session.is_finish
                  ? 'text-green-600 hover:text-green-700 underline text-sm'
                  : 'text-gray-600 cursor-default text-sm',
              action: () => {
                if (
                  !e.evaluation &&
                  e.block &&
                  e.block.session &&
                  e.block.session.is_finish
                ) {
                  this.evaluation_toggle = true;
                  this.evaluation_reservation = e;
                }
              },
            },
          ],
        };
      });
    },
  },
};
</script>

<style scoped>
.min-height {
  min-height: 80vh;
}
</style>
