<template>
  <modal-container v-if="value">
    <form
      @submit.prevent="handleSubmit"
      v-click-outside="pressOutside"
      class="inline-block align-middle transform transition-all sm:max-w-2xl w-full"
    >
      <div class="shadow-xl rounded-md">
        <div class="bg-white px-4 py-5 sm:p-6 rounded-t-md">
          <div v-if="!complete" class="grid grid-cols-6 gap-6">
            <!-- title -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Evaluación
              </h3>
              <p
                v-if="
                  reservation && reservation.block && reservation.block.user
                "
                class="mt-1 text-left max-w-2xl text-sm text-gray-500"
              >
                <label class="capitalize">{{
                  `${reservation.block.user.names} ${reservation.block.user.lastnames}`
                }}</label>
                <br />
                <label>
                  {{ this.formatDateNative(reservation.block.start_date) }}
                </label>
                <label>
                  {{ this.formatDateNativeHour(reservation.block.start_date) }}
                </label>
              </p>
            </div>
            <!-- good or bad -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="diseases"
                class="block text-sm font-medium text-gray-700"
                >Preguntas</label
              >
              <div class="mt-1 w-full">
                <div class="w-full flex flex-col">
                  <div class="w-full flex justify-between my-2 items-center">
                    <span class="text-sm text-gray-700 text-left"
                      >¿Considera que recibió una buena atención en esta
                      consulta?</span
                    >
                    <div class="flex">
                      <div class="flex items-center mr-4">
                        <button
                          @click="
                            () => {
                              form.good = true;
                            }
                          "
                          type="button"
                          :class="
                            form.good && typeof form.good === 'boolean'
                              ? 'bg-green-600'
                              : 'bg-gray-200'
                          "
                          class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                        >
                          <svg
                            v-if="form.good && typeof form.good === 'boolean'"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </button>
                        <span class="text-gray-600">Si</span>
                      </div>
                      <div class="flex items-center mr-4">
                        <button
                          @click="
                            () => {
                              form.good = false;
                            }
                          "
                          type="button"
                          :class="
                            !form.good && typeof form.good === 'boolean'
                              ? 'bg-green-600'
                              : 'bg-gray-200'
                          "
                          class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                        >
                          <svg
                            v-if="!form.good && typeof form.good === 'boolean'"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </button>
                        <span class="text-gray-600">No</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="submitted && !$v.form.good.validAnswer"
                class="text-sm text-red-600"
              >
                Esta respuesta es obligatoria
              </div>
            </div>
            <!-- comments -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="comments"
                class="block text-sm font-medium text-gray-700"
                >Comentarios <small>(opcional)</small></label
              >
              <div class="mt-1 w-full">
                <textarea
                  v-model="form.comments"
                  type="text"
                  name="comments"
                  id="comments"
                  :class="
                    submitted && $v.form.comments.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block h-40 resize-none border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                ></textarea>
              </div>
            </div>

            <!-- end of form inputs -->
          </div>
          <div v-if="complete" class="grid grid-cols-6 gap-6">
            <!-- title -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <h3 class="text-lg flex font-medium leading-6 text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                  />
                </svg>
                <span class="ml-2">Muchas gracias.</span>
              </h3>
              <p class="mt-1 text-left max-w-2xl text-sm text-gray-500">
                Su respuesta nos ayuda a mejorar nuestro servicio.
              </p>
            </div>
          </div>
        </div>
        <div class="bg-gray-100 px-4 py-3 text-right sm:px-6 rounded-b-md">
          <button
            type="button"
            @click="close"
            class="inline-flex mr-2 justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            Cerrar
          </button>
          <button
            v-if="!complete"
            type="submit"
            class="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Evaluar
          </button>
        </div>
      </div>
    </form>
  </modal-container>
</template>

<script>
import moment from 'moment';
import ClickOutside from 'vue-click-outside';
import ModalContainer from '@/components/modals/ModalContainer.vue';
import EvaluationService from '@/services/evaluationService';

export default {
  name: 'CreateUserModal',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
    reservation: {
      type: Object,
      default: null,
      required: false,
    },
  },
  components: {
    'modal-container': ModalContainer,
  },
  data() {
    return {
      firstPress: true,
      form: {
        comments: '',
        good: null,
      },
      submitted: false,
      complete: false,
    };
  },
  validations: {
    form: {
      comments: {},
      good: {
        validAnswer: answer => {
          return typeof answer === 'boolean';
        },
      },
    },
  },
  methods: {
    formatDateNative(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('L');
    },
    formatDateNativeHour(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('HH:mm A');
    },
    pressOutside() {
      if (!this.firstPress) {
        this.close();
      }
      if (this.firstPress) {
        this.firstPress = false;
      }
    },
    close() {
      if (this.complete) {
        this.$emit('then', {
          update: true,
        });
      } else {
        this.$emit('then', {});
      }
    },
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      EvaluationService.evaluate({
        comments: this.form.comments,
        reservation_id: this.reservation.id,
        good: this.form.good,
      })
        .then(() => {
          this.complete = true;
        })
        .catch(err => {
          console.log(`failed to create evaluation: ${err}`);
        });
    },
  },
  watch: {
    'reservation.id'() {
      if (!this.reservation.evaluation) {
        this.close();
      }
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
