var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col justify-start items-start min-height"},[(_vm.evaluation_toggle)?_c('evaluation-modal',{attrs:{"reservation":_vm.evaluation_reservation},on:{"then":_vm.updateData},model:{value:(_vm.evaluation_toggle),callback:function ($$v) {_vm.evaluation_toggle=$$v},expression:"evaluation_toggle"}}):_vm._e(),_c('span',{staticClass:"text-xl text-gray-900 font-semibold"},[_vm._v("Próximas Reservas "),_c('small',[_vm._v("["+_vm._s(_vm.next.length)+"]")])]),_c('div',{staticClass:"w-full"},[_c('table-component',{staticClass:"w-full mt-4",attrs:{"loading":_vm.loadingNext,"head":[
        { key: 'code', label: 'Código' },
        { key: 'name', label: 'Nombre Profesional' },
        { key: 'date', label: 'Fecha Reserva' },
        { key: 'status', label: 'Estado Reserva' },
        { key: 'bond', label: 'Cargar Bono' } ],"body":_vm.bodyNext}})],1),_c('span',{staticClass:"text-xl text-gray-900 font-semibold mt-8"},[_vm._v("Reservas pasadas "),_c('small',[_vm._v("["+_vm._s(_vm.purchases.length)+"]")])]),_c('table-component',{staticClass:"w-full mt-4",attrs:{"loading":_vm.loadingPast,"head":[
      { key: 'code', label: 'Código' },
      { key: 'name', label: 'Nombre Profesional' },
      { key: 'date', label: 'Fecha Reserva' },
      { key: 'status', label: 'Estado Reserva' },
      { key: 'bond', label: 'Cargar Bono' },
      { key: 'evaluation', label: 'Evaluación' } ],"body":_vm.bodyPast}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }